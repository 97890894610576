import * as React from 'react';
const { Gitgraph } = require("@gitgraph/react");

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
        <Gitgraph>
              {(gitgraph) => {

                  var renderMessage = function (commit, message) {
                      return React.createElement(
                          'g',
                          { transform: 'translate(0, ' + commit.style.dot.size + ')' },
                          React.createElement(
                              'text',
                              { fill: commit.style.dot.color, alignmentBaseline: 'central' },
                              commit.hashAbbrev,
                              ' - ',
                              commit.subject
                          ),
                          React.createElement(
                              'foreignObject',
                              { width: '600', x: '10', y:'10' },
                              React.createElement(
                                  'div',
                                  null,
                                  message
                              )
                          )
                      );
                  };

                  var renderMessage2 = function (commit) {
                      return React.createElement(
                          'text',
                          { y: commit.style.dot.size, alignmentBaseline: 'central', fill: commit.style.dot.color },
                          commit.hashAbbrev,
                          ' - ',
                          commit.subject
                      );
                  };



                  var addcommit = function(branch, subject, message) {


                      const addCommit = function (commit) { return renderMessage(commit, message); };
                      branch.commit({
                          subject: subject,
                          renderMessage: addCommit

                      });


                  }

                  // Simulate git commands with Gitgraph API.
                  const master = gitgraph.branch("master");
                  master.commit("Heriot Watt University");
                  master.commit("Strathclyde University");

                  const bgl = master.branch("Bgl Group");
                  addcommit(bgl, 'Pet Insurance Comparison', "I'm a little teapot");
                  addcommit(bgl, 'Life Insurance Comparison', "I'm a little teapot");
                  addcommit(bgl, 'Home Insurance Comparison', "I'm a little teapot");
                  addcommit(bgl, 'Software Engineer', "I'm a little teapot");

                  master.merge(bgl).tag("v1.0.0");


                  const capita = master.branch("Capita Plc");
                  addcommit(capita, 'Sims Online Update Service', "I'm a little teapot");
                  addcommit(capita, 'Sims Service Manager', "I'm a little teapot");
                  addcommit(capita, 'Sims Options', "I'm a little teapot");
     
                  master.merge(capita).tag("v2.0.0");

                  const itGoverance = master.branch("IT Goverance Ltd");

                  addcommit(itGoverance, 'Sims Online Update Service', "I'm a little teapot");

                  master.merge(itGoverance).tag("v3.0.0");

                  const smartSpace = master.branch('SmartSpace Plc');

                  addcommit(smartSpace, 'SmartSpace Plc', "I'm a little teapot");

                  master.merge(smartSpace).tag("v3.0.0");


                  const easyJet = master.branch("EasyJet Plc");

                  addcommit(easyJet, 'ERes', "I'm a little teapot");
                  addcommit(easyJet, 'VT100', "I'm a little teapot");
                  addcommit(easyJet, 'Technical Architect', "I'm a little teapot");
                  addcommit(easyJet, 'SmartSpace Plc', "I'm a little teapot");
                  master.merge(easyJet).tag("v4.0.0");


                  const capacitas = master.branch("Capacitas Ltd");

                  capacitas.commit({
                      subject: 'Technical Architect Consultant',
                      author: 'Kevin Murray <kevin.murray@anysolutions.ltd>',

                  });

                  master.merge(capacitas).tag("v5.0.0");
      
                 

                 
              }}
        </Gitgraph>
    </div>
  );
};
