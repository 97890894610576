import React from "react";

export const Team = (props) => {
  return (
      <div id="consultants" className="text-center">
          <div className="container">
              <div className="section-title">
                  <h2>Consultant</h2>
              </div>
              {props.data ? props.data.map((d, i) => (
                 <div key={`${d.name}-${i}`}  id="row">    
                 <div className="col-xs-12 col-md-6">
                      <div className="thumbnail">
                          {" "}
                          <img src={d.img} alt="..." className="team-img" />
                          <div className="caption">
                              <h4>{d.name}</h4>
 
                          </div>
                      </div>
                  </div>
                  <div className="col-xs-12 col-md-6 team">
                            <div>
                                  <p>
                                      {d.text}
                                  </p>

                                  </div>
                              </div>
                   </div>

              ))
            : "loading"}

          </div>
      </div>
  );
};
