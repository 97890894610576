import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
        <div className="container">
            <div className="section-title">
                <h2>Technology</h2>
                <p>
                    An in depth knowledge of technology's including:
                </p>
            </div>
              <div className="row portfolio-items">
               
                    {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                      className="col-xs-4 col-sm-4 col-md-3 col-lg-3"
                  >
                    <Image
                      title={d.title}
                      image={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          
            </div>
        </div>
    </div>
  );
};
