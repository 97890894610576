import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
  
                          <div className="col-xs-8 col-xs-offset-1 col-sd-6 col-sd-offset-2 col-md-6 col-md-offset-2 intro-text">
                              <img src="img/intro-logo.webp" className="img-responsive center-block" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
