import { useState } from "react";
import emailjs from "@emailjs/browser";
import React from "react";
import { useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha"

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {

    const key = "6Lf6MgglAAAAABOCNQBszuSE1JyInSKDMLddyXaZ";
   

    const [{ name, email, message }, setState] = useState(initialState);
    const [isSuccessVisible, setIsSuccessVisible] = React.useState(false);
    const [isErrorVisible, setIsErrorVisible] = React.useState(false);
    const [isSendingVisible, setIsSendingVisible] = React.useState(false);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
      setState((prevState) => ({ ...prevState, [name]: value }));
      setIsSuccessVisible(false); setIsErrorVisible(false);
  };
  const clearState = () => setState({
       ...initialState
  });


  const handleSubmit = (e) => {
      e.preventDefault();
      setIsSendingVisible(true);
    console.log(name, email, message);
    emailjs
        .sendForm("service_90qsa2m", "template_0sqxeup", e.target, "i1uCTP2iQV8dKisps")
      .then(
        (result) => {
              console.log(result.text);          
              clearState();
              setIsSendingVisible(false);
              setIsSuccessVisible(true);
             
        },
        (error) => {
            console.log(error.text);
            setIsSendingVisible(false);
            setIsErrorVisible(true);
        }
      );
  };


  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-sm-8 col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
             <form name="sentMessage" validate  onSubmit={handleSubmit}>
                              <div className="row">
                                  
                    <div className="col-md-6">

                   

                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                                              required
                                              value={name}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                                              required
                                              value={email}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                                      required
                                      value={message}
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                              </div>
                              {isSendingVisible && <div className='alert alert-info'>Message Sending!</div>}
                              {isSuccessVisible && <div className='alert alert-success'>Message Sent!</div>}
                              {isErrorVisible && <div className='alert alert-danger'>Message Failed!</div>}  
                  <ReCAPTCHA formNoValidate sitekey={key} />

                   

                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                              </button>
                             
              </form>
            </div>
          </div>
                  <div className="col-sm-3 col-sm-offset-1 col-md-3 col-md-offset-1 contact-info">
                      <div className="contact-item">
                          <h3>Contact Info</h3>
             
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                              </span>
                              {props.data ? props.data.name : "loading"}
                          </p>
                          <p>
                              {props.data ? props.data.address1 : "loading"}
                          </p>
                          <p>
                              {props.data ? props.data.address2 : "loading"}
                          </p>
                          <p>
                              {props.data ? props.data.address3 : "loading"}
                          </p>
                          <p>
                              {props.data ? props.data.address4 : "loading"}
                          </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
                      &copy;2023 Any Solutions Limited Company Number 11396089 Vat 408057606. </p>

        </div>
      </div>
    </div>
  );
};
